$bezier: cubic-bezier(0.62, 0.28, 0.23, 0.99);
$blueText: #004e95;
$greyHover: #dce3e4;
$greyText: #6f6f6f;
$sunflower: #f8c513;

// .wrapper {
//   box-sizing: border-box;
//   margin-bottom: 0;
//   margin-left: auto;
//   margin-right: auto;
//   margin-top: 0;
//   max-width: 1440px;
//   padding: 0 25px;
// }

// .main-navigation {
//   // .mobile-trigger {
//   //   @media (min-width: 900px) {
//   //     display: none;
//   //   }
//   // }

//   // .header-main > .wrapper {
//   //   align-items: center;
//   //   display: flex;
//   //   justify-content: space-between;
//   // }

//   // .logo {
//   //   svg {
//   //     height: 27px;
//   //     max-width: 134px;
//   //     padding-bottom: 12px;
//   //     padding-top: 18px;
//   //     width: 100%;
//   //   }
//   // }

//   // .header-mini {
//   //   @media (max-width: 899px) {
//   //     display: none;
//   //   }
//   //   background-color: #f3f6f7;
//   //   color: $greyText;

//   //   .wrapper {
//   //     text-align: right;
//   //   }

//   //   a {
//   //     background-color: transparent;
//   //     color: $greyText;
//   //     display: inline-block;
//   //     font-size: 13px;
//   //     font-weight: bold;
//   //     letter-spacing: 1px;
//   //     line-height: 15px;
//   //     padding: 8px 25px;
//   //     text-decoration: none;
//   //     text-transform: uppercase;
//   //     transition: background-color 250ms $bezier, color 250ms $bezier;

//   //     &.tel {
//   //       color: $blueText;
//   //     }

//   //     &:hover {
//   //       background-color: $greyHover;
//   //       color: #383838;
//   //     }
//   //   }

//   //   .wrapper > a:last-child {
//   //     margin-right: -25px;
//   //   }
//   // }

//   .navigation-items {
//     line-height: 19px;
//     list-style-type: none;
//     margin: 0;
//     padding: 0;
//     font-size: 16px;
//     text-transform: uppercase;
//     font-weight: 500;
//     color: #1f2833;
//     letter-spacing: 1px;
//   }

//   .navigation-item {
//     float: left;
//     margin-left: 20px;
//   }

//   .navigation-link {
//     color: #383838;
//     display: block;
//     text-align: center;
//     letter-spacing: 1px;
//     padding-top: 31px;
//     padding-bottom: 23px;
//     padding-left: 10px;
//     padding-right: 10px;
//     text-decoration: none;
//     border-bottom: 6px solid #fff;
//     transition: border-bottom 250ms $bezier;

//     font-size: 16px;
//     font-weight: 500;

//     &:hover,
//     &.current {
//       border-bottom: 6px solid $sunflower;
//     }
//   }

//   .booking-button {
//     border-radius: 7px;
//     background-color: #f6c414;
//     color: #00376c;
//     font-family: Roboto;
//     font-size: 14px;
//     font-stretch: normal;
//     font-style: normal;
//     font-weight: bold;
//     letter-spacing: 0.2px;
//     line-height: normal;
//     text-align: center;
//     display: block;
//     margin-top: 20px;
//     padding-right: 15px;
//     padding-left: 15px;
//     padding-top: 11px;
//     padding-bottom: 11px;
//     text-decoration: none;
//     transition: background-color 250ms $bezier;
//     &:hover {
//       background-color: #fd0;
//     }
//   }
// }

.mobile-navigation {
  // display: none;
  // position: fixed;
  // bottom: 0;
  // top: 0;
  // left: 0;
  // width: 100%;
  // z-index: 500;
  // height: 0;
  // background: white;

  .cross {
    cursor: pointer;
    padding: 25px;
    position: absolute;
    right: 0;
    top: 0;

    svg {
      width: 15px;
      height: 15px;
      display: block;
    }
  }
  &.active {
    display: block;
    height: 100vh;
  }

  > div {
    // display: flex;
    // align-items: center;
    // flex-direction: column;
    // justify-content: center;

    // box-sizing: content-box;
    // max-width: 250px;
    // margin: 0 auto;
    // padding: 0 25px;
    // position: sticky;
    // top: 0;
    // opacity: 1;
    // height: calc(100vh + 1px);
    // overflow: hidden;
    // pointer-events: none;
    // width: 100%;
  }

  // &.active {
  //   > div {
  //     pointer-events: all;
  //     overflow: visible;
  //   }
  // }

  // .buttons {
  //   box-sizing: border-box;
  //   margin-bottom: 25px;
  //   margin-top: 0;
  //   margin-left: auto;
  //   margin-right: auto;
  //   text-align: center;
  //   width: 100%;

  //   a {
  //     background-color: #01396c;
  //     border-radius: 7px;
  //     color: white;
  //     display: inline-block;
  //     font-size: 14px;
  //     font-weight: bold;
  //     padding: 5px 10px;
  //     text-decoration: none;
  //     text-transform: uppercase;
  //     transition: background-color 250ms $bezier;

  //     &:hover {
  //       background-color: #004e95;
  //     }
  //   }
  // }

  // .mobile-navigation-items {
  //   list-style: none;
  //   padding: 0;
  //   margin: 0;
  // }

  // .mobile-navigation-link {
  //   border-left: 6px solid white;
  //   color: #01396c;
  //   cursor: pointer;
  //   display: inline-block;
  //   font-size: 18px;
  //   font-stretch: normal;
  //   font-style: normal;
  //   font-weight: 500;
  //   left: -27px;
  //   letter-spacing: 1.13px;
  //   line-height: normal;
  //   margin-bottom: 4px;
  //   padding-bottom: 10px;
  //   padding-left: 20px;
  //   padding-right: 20px;
  //   padding-top: 10px;
  //   position: relative;
  //   text-decoration: none;
  //   text-transform: uppercase;
  //   transition: border-left 250ms $bezier;
  //   width: 100%;
  //   &:hover,
  //   &.current {
  //     border-left: 6px solid #f6c413;
  //   }
  // }

  // .mobile-booking-button {
  //   border-radius: 7px;
  //   background-color: #f6c414;
  //   color: #00376c;
  //   display: block;
  //   font-size: 14px;
  //   font-stretch: normal;
  //   font-style: normal;
  //   font-weight: bold;
  //   letter-spacing: 0.2px;
  //   line-height: normal;
  //   text-align: center;
  //   margin-top: 20px;
  //   margin-bottom: 27px;
  //   padding-right: 15px;
  //   padding-left: 15px;
  //   padding-top: 11px;
  //   padding-bottom: 11px;
  //   text-decoration: none;
  //   transition: background-color 250ms $bezier;
  //   text-transform: uppercase;
  //   &:hover {
  //     background-color: #fd0;
  //   }
  // }

  // .mobile-booking-contact-us-button {
  //   font-size: 14px;
  //   font-weight: bold;
  //   font-stretch: normal;
  //   font-style: normal;
  //   line-height: normal;
  //   letter-spacing: 1px;
  //   color: #6f6f6f;
  //   text-transform: uppercase;
  //   text-decoration: none;
  // }
}

// @-webkit-keyframes zoomIn {
//   from {
//       opacity: 0;
//       -webkit-transform: scale3d(.3, .3, .3);
//       transform: scale3d(.3, .3, .3);
//   }

//   50% {
//       opacity: 1;
//   }
// }

// .zoomIn {
//   animation-name: zoomIn;
// }

// .animated {
//   animation-duration: .3s;
//   animation-fill-mode: both;
// }

// .modal {
//   display: none;
//   position: fixed;
//   z-index: 1;
//   padding:0.5%;
//   left: 0;
//   top: 0;
//   width: 100%;
//   height: 100%;
//   overflow: auto;
// }

// .modal-content {
//   position: relative;
//   margin: auto;
//   padding: 0;
//   width: 100%;
//   height: 98vh;
//   box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
//   animation-name: animatetop;
//   animation-duration: 0.4s;
//   background-color: #FFF0A5;
// }

// .modal-header {
//   padding: 2px 16px;
//   background-color: #FFF0A5;
//   border-bottom: none;
//   height:35vh;
// }

// .modal-body {
//   padding: 2px 16px;
//   background-color: #FFF0A5;
// }

// .modal-footer {
//   padding: 2px 16px;
//   background-color: #FFF0A5;
//   color: black;
//   border-top: none;
//   text-align: center;
// }
