* {
    font-family: "Roboto", "Franklin Gothic Medium", Tahoma, sans-serif;
}
body{
    margin: 0px;
}
button, input, a, button:active, input:active, a:active, button:focus, input:focus, a:focus {
  outline: none !important;
}
#app {
  clear: both;
}
